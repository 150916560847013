<template>
  <div class="container">
    <div style="position: absolute; top: 65px; right: 20px;">
      <a-button type="primary" class="ML12" @click="submit">确认</a-button>
      <a-button class="ML12" @click="$router.back()">取消</a-button>
    </div>
    <a-form-model ref="form"
                  :rules="rules"
                  style="margin-top: 20px;"
                  :model="formData"
                  :colon="false">
        <a-form-model-item label="协议内容" prop="agreementContents" style="width: 100%;">
          <vue-editor ref="addQuillEditor" v-model="formData.agreementContents" :editor-toolbar="customToolbar" useCustomImageHandler @image-added="handleImageAdded" placeholder="请输入" />
        </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import * as http from '@/api/set/http.api'

export default {
  name: 'insert',
  components: {
    VueEditor
  },
  data() {
    return {
      formData: {
        id: JSON.parse(localStorage.getItem("yyd_plat_type")).id,
        agreementContents: JSON.parse(localStorage.getItem("yyd_plat_type")).agreementContents
      },
      rules: {
        agreementContents: [{required: true, message: '请编辑协议', trigger: 'change'}],
      },
      customToolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6] }], // 标题
        ['bold', 'italic', 'underline'], // 加粗\斜体\下划线
        ['italic'], // 斜体----也可以拆开来写，用于换位置顺序
        [{align: ''}, {align: 'center'}, {align: 'right'}], // 对齐
        [{ list: 'ordered'}, { list: 'bullet' }, { list: 'check' }], // 有序无序列表
        [{background: []}, {color: []}], // 背景色
        ['image'], // 图片、链接
        ['strike'], // 作废（文字中间一横）
        ['underline'],
        ['clean'], // 清楚格式（所谓去掉背景色这些花里胡巧）
        ['code'], // 代码
        ['code-block'], // 代码块
        // ['video'] //视频
      ],
    }
  },
  mounted() {
    this.authType = this.$route.query.authType
    this.userType = this.$route.query.userType
  },
  methods: {
    // 图片
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append("Upload", file);
      // 发请求
      http.editPlatformHttp(formData)
        .then((res) => {
          // 把图片放在光标所在的位置
          Editor.insertEmbed(cursorLocation, "image", res.data.data.filename);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {
      console.log(this.formData)
      // 发请求
      http.editPlatformHttp(this.formData)
        .then((res) => {
          this.$router.back()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 粘贴图片
    onPasteUpload(ref) {
      let quill = null
      if (ref === 'addQuillEditor' || ref === 'causesQuillEditor' || ref === 'reasontQuillEditor') {
        quill = this.$refs[ref].quill
      } else {
        quill = this.$refs[ref][0].quill
      }
      quill.root.addEventListener("paste", (evt) => {
        if (evt.clipboardData && evt.clipboardData.files && evt.clipboardData.files.length) {
          evt.preventDefault();
          [].forEach.call(evt.clipboardData.files, (file) => {
            if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
              return;
            }
            const formData = new FormData();
            formData.append("Upload", file);
            http.editPlatformHttp(formData).then((res) => {
              if (res.data.code === 0) {
                let length = quill.getSelection().index; // 光标位置
                // 插入图片地址
                quill.insertEmbed(length, "image", res.data.data.filename);
                // 光标后移一位
                quill.setSelection(length + 1);
              }
            })
              .catch((err) => {
                console.log(err);
              });
          });
        }
      }, false);
    },
    handleChangeIdCard(info) {
    },
    handleChangePositive(info) {
    },
    handleChangeOther(info) {
    }
  }
}
</script>

<style scoped lang="less">
.container {
  padding: 20px;
  background: #ffffff;

  .label {
    width: 100px;
  }
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.margin-bottom {
  margin-bottom: 20px;
}

.upload-btn {
  width: 411px;
  height: 244px;
  font-weight: bold;
  font-size: 20px;
  background: #F2F2F2;
}
.upload-btn-02 {
  width: 285px;
  height: 244px;
  font-weight: bold;
  font-size: 20px;
  background: #F2F2F2;
}

.map {
  width: 686px;
  height: 432px;
  background: #d9d9d9;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

/deep/ .ant-form-item-required {
  font-size: 18px;
  font-weight: bold;
}
</style>
